import React, { useState , useEffect} from 'react'
import { Col, Row, Dropdown, Container, Offcanvas } from 'react-bootstrap'
import { NavLink, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import LogoutModal from '../Modals/LogoutModal';
import WalletconnectModal from "../Modals/WalletconnectModal";
import NetworkModal from "../Modals/NetworkModal";
import { WALLET_ADDRESS } from '../constants'
import { CHAINS, FRONT_URL, ANALYTICS_URL } from '../Config/env';
import { removeWallteAddress, walletControl,getWalletAddress } from '../Lib/localStorage';

function Header({ title }) {

    //modal
    const [showLogout, setShowLogout] = useState(false);
    const [showWallet, setShowWallet] = useState(false);
    const [showNetwork, setShowNetwork] = useState(false);
    const [walletmodalshow, setWalletmodalshow] = useState(false)
    const [disconnect, setDisconnect] = useState(false);

    const { networkSwitch } = useSelector((state) => state.networkSwitching)
    console.log("networkSwitching", networkSwitch)

    const userdetail = useSelector((state) => state.admin)
    console.log("userdetail_userdetail", userdetail)

    const walletdetail = useSelector((state) => state.wallet)
    console.log("walletdetail", walletdetail)

    const dispatch = useDispatch()
    const location = useLocation();

    const walletdis = () => {
        dispatch(walletControl('disconnect'))
        dispatch({
            type: WALLET_ADDRESS,
            payload: ''
        })
        removeWallteAddress()
        sessionStorage.setItem('walletStatus', 'disconnect')
        setDisconnect(false)
    }

    const handleCloseLogout = () => setShowLogout(false);
    const handleShowLogout = () => setShowLogout(true);

    const handleCloseWallet = () => setShowWallet(false);
    const handleShowWallet = () => setShowWallet(true);

    const handleCloseNetwork = () => setShowNetwork(false);
    const handleShowNetwork = () => setShowNetwork(true);

    // const switcher = useSelector(state => state.reducer.switcher)
    const [mobSearch, setMobSearch] = useState(false)

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const setSwitcher = (val) => {
        dispatch({
            type: "switching",
            value: { switcher: val }
        })
    }
    console.log(disconnect,'disconnect')

    useEffect(()=>{
        if(getWalletAddress() == ''){
          handleShowWallet()
        }
      },[walletdetail])


    return (
        <>
            <div className='header mt-1 mt-sm-3 py-2 px-2 position-relative'>
                {mobSearch &&
                    <div className='mob_searchbar_holder p-3 d-flex justify-content-start align-items-center gap-3'>
                        <input type='text' placeholder='Search' className='mob_searchbar_inp flex-grow-1' />
                        <i class="fa-solid fa-xmark" onClick={() => setMobSearch(false)} />
                    </div>}
                <Row className='align-items-center'>
                    <Col xl={6} lg={4} md={4} xs={8} className='d-flex gap-3'>
                        <p className='header_title d-block d-xl-none' onClick={handleShow}><i class="fa-solid fa-bars" /></p>
                        <p className='header_title'>{title}</p>
                    </Col>
                    <Col xl={6} lg={8} md={8} xs={4} className='d-flex justify-content-end align-items-center gap-3'>

                        {/* mobile searchbar */}
                        <button className='grey_small_primary d-none d-lg-flex' onClick={handleShowNetwork}>
                            {networkSwitch && networkSwitch != '' &&
                                <>
                                    <img src={CHAINS[networkSwitch]?.IMAGE} alt='' className={`netimg me-2`} />
                                    {console.log("NAME", CHAINS[networkSwitch]?.NAME, networkSwitch, CHAINS[networkSwitch])}
                                    <p className={`d-none d-lg-block mb-0`}>{CHAINS[networkSwitch]?.NAME}</p>
                                </>
                            }
                        </button>


                        {/* <img src={require('../assets/images/bsc.png')} alt='' className={`netimg me-2`} />
                            BNB Chain */}

                        <button className='grey_small_primary_mob d-block d-lg-none' onClick={handleShowNetwork}>
                            {networkSwitch && networkSwitch != '' &&
                                <>
                                    <img src={CHAINS[networkSwitch]?.IMAGE} alt='' className={`netimg`} />
                                    {console.log("NAME", CHAINS[networkSwitch]?.NAME, networkSwitch, CHAINS[networkSwitch])}
                                    <p className={`d-none d-lg-block mb-0`}>{CHAINS[networkSwitch]?.NAME}</p>
                                </>
                            }

                            {/* <img src={require('../assets/images/bsc.png')} alt='' className={`netimg`} /> */}

                        </button>

                        {userdetail && userdetail?.walletStatus == 'connect' ?
                            <div className={`headdrop orange_small_primary orange_small_primary_add d-none d-lg-block`}>
                                <div className={`btnsec`} onClick={() => { setDisconnect(!disconnect) }}>
                                    {console.log("wallet", userdetail?.walletStatus == 'connect')}

                                    <svg viewBox="0 0 24 24" color="primary" width="24px" xmlns="http://www.w3.org/2000/svg" class="sc-bdnxRM yzynT"><path fill-rule="evenodd" clip-rule="evenodd" d="M17 4C18.5 4 19 4.5 19 6L19 8C20.1046 8 21 8.89543 21 10L21 17C21 19 20 20 17.999 20H6C4 20 3 19 3 17L3 7C3 5.5 4.5 4 6 4L17 4ZM5 7C5 6.44772 5.44772 6 6 6L19 6L19 8L6 8C5.44772 8 5 7.55229 5 7ZM17 16C18 16 19.001 15 19 14C18.999 13 18 12 17 12C16 12 15 13 15 14C15 15 16 16 17 16Z"></path></svg>
                                    <button className={`btn dropbtns`} ><span className={`dropbtnstext`}>
                                        {`${(walletdetail?.walletaddress).substring(0, 5)}...${walletdetail?.walletaddress.substring(38, 42)}`}
                                    </span>
                                        {/* <FaAngleDown fill='#fff' className={`${cascading.arrowicon}`} /> */}
                                    </button>
                                </div>
                                {disconnect && disconnect &&

                                    <div className='dropdown_header'>
                                        <ul>

                                            <li onClick={() => walletdis()}>Disconnect
                                                <svg viewBox="0 0 24 24" fill='#fff' color="text" width="20px" xmlns="http://www.w3.org/2000/svg" class="sc-bdnxRM bNbPUR">
                                                    <path d="M16.3 8.09014C15.91 8.48014 15.91 9.10014 16.3 9.49014L18.2 11.3901H9C8.45 11.3901 8 11.8401 8 12.3901C8 12.9401 8.45 13.3901 9 13.3901H18.2L16.3 15.2901C15.91 15.6801 15.91 16.3001 16.3 16.6901C16.69 17.0801 17.31 17.0801 17.7 16.6901L21.29 13.1001C21.68 12.7101 21.68 12.0801 21.29 11.6901L17.7 8.09014C17.31 7.70014 16.69 7.70014 16.3 8.09014ZM4 19.3901H11C11.55 19.3901 12 19.8401 12 20.3901C12 20.9401 11.55 21.3901 11 21.3901H4C2.9 21.3901 2 20.4901 2 19.3901V5.39014C2 4.29014 2.9 3.39014 4 3.39014H11C11.55 3.39014 12 3.84014 12 4.39014C12 4.94014 11.55 5.39014 11 5.39014H4V19.3901Z"></path></svg></li>
                                        </ul>

                                    </div>
                                }
                            </div>

                            :
                            <>
                                <button className='orange_small_primary d-none d-lg-block' onClick={handleShowWallet}>
                                    Connect Wallet</button>

                            </>
                        }


                        {userdetail && userdetail?.walletStatus == 'connect' ?
                            <div className={`headdrop orange_small_primary orange_small_primary_add d-block d-lg-none`}>
                                <div className={`btnsec`} onClick={() => { setDisconnect(!disconnect) }}>
                                    {console.log("wallet", userdetail?.walletStatus == 'connect')}

                                    <svg viewBox="0 0 24 24" color="primary" width="24px" xmlns="http://www.w3.org/2000/svg" class="sc-bdnxRM yzynT"><path fill-rule="evenodd" clip-rule="evenodd" d="M17 4C18.5 4 19 4.5 19 6L19 8C20.1046 8 21 8.89543 21 10L21 17C21 19 20 20 17.999 20H6C4 20 3 19 3 17L3 7C3 5.5 4.5 4 6 4L17 4ZM5 7C5 6.44772 5.44772 6 6 6L19 6L19 8L6 8C5.44772 8 5 7.55229 5 7ZM17 16C18 16 19.001 15 19 14C18.999 13 18 12 17 12C16 12 15 13 15 14C15 15 16 16 17 16Z"></path></svg>
                                </div>
                                {disconnect && disconnect &&
                                    <div className='dropdown_header'>
                                        <ul>
                                            <li>
                                                <button className={`btn dropbtns`} ><span className={`dropbtnstext`}>
                                                    {`${(walletdetail?.walletaddress).substring(0, 5)}...${walletdetail?.walletaddress.substring(38, 42)}`}
                                                </span>
                                                    {/* <FaAngleDown fill='#fff' className={`${cascading.arrowicon}`} /> */}
                                                </button>
                                            </li>
                                            <li onClick={() => walletdis()}>Disconnect
                                                <svg viewBox="0 0 24 24" fill='#fff' color="text" width="20px" xmlns="http://www.w3.org/2000/svg" class="sc-bdnxRM bNbPUR">
                                                    <path d="M16.3 8.09014C15.91 8.48014 15.91 9.10014 16.3 9.49014L18.2 11.3901H9C8.45 11.3901 8 11.8401 8 12.3901C8 12.9401 8.45 13.3901 9 13.3901H18.2L16.3 15.2901C15.91 15.6801 15.91 16.3001 16.3 16.6901C16.69 17.0801 17.31 17.0801 17.7 16.6901L21.29 13.1001C21.68 12.7101 21.68 12.0801 21.29 11.6901L17.7 8.09014C17.31 7.70014 16.69 7.70014 16.3 8.09014ZM4 19.3901H11C11.55 19.3901 12 19.8401 12 20.3901C12 20.9401 11.55 21.3901 11 21.3901H4C2.9 21.3901 2 20.4901 2 19.3901V5.39014C2 4.29014 2.9 3.39014 4 3.39014H11C11.55 3.39014 12 3.84014 12 4.39014C12 4.94014 11.55 5.39014 11 5.39014H4V19.3901Z"></path></svg></li>
                                        </ul>

                                    </div>
                                }

                            </div> :
                            <>
                                <button className='orange_small_primary_mob d-block d-lg-none' onClick={handleShowWallet}>
                                    <svg viewBox="0 0 24 24" color="primary" width="24px" xmlns="http://www.w3.org/2000/svg" class="sc-bdnxRM yzynT">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M17 4C18.5 4 19 4.5 19 6L19 8C20.1046 8 21 8.89543 21 10L21 17C21 19 20 20 17.999 20H6C4 20 3 19 3 17L3 7C3 5.5 4.5 4 6 4L17 4ZM5 7C5 6.44772 5.44772 6 6 6L19 6L19 8L6 8C5.44772 8 5 7.55229 5 7ZM17 16C18 16 19.001 15 19 14C18.999 13 18 12 17 12C16 12 15 13 15 14C15 15 16 16 17 16Z"></path></svg>
                                </button>

                            </>
                        }






                        {/* <button className='orange_small_primary d-none d-lg-block' onClick={handleShowWallet}>
                            Connect Wallet</button>
                        <button className='orange_small_primary_mob d-block d-lg-none' onClick={handleShowWallet}>
                            <svg viewBox="0 0 24 24" color="primary" width="24px" xmlns="http://www.w3.org/2000/svg" class="sc-bdnxRM yzynT">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M17 4C18.5 4 19 4.5 19 6L19 8C20.1046 8 21 8.89543 21 10L21 17C21 19 20 20 17.999 20H6C4 20 3 19 3 17L3 7C3 5.5 4.5 4 6 4L17 4ZM5 7C5 6.44772 5.44772 6 6 6L19 6L19 8L6 8C5.44772 8 5 7.55229 5 7ZM17 16C18 16 19.001 15 19 14C18.999 13 18 12 17 12C16 12 15 13 15 14C15 15 16 16 17 16Z"></path></svg>
                        </button> */}



                        {/* end of mobile searchbar */}


                        {/* <div className='header_profileImg_holder'>
                    <Dropdown className='header_profile_drop' drop='down'>
      <Dropdown.Toggle variant="success" id="dropdown-basic">
                    <img src={require('../assets/images/profile.png')} className='img-fluid header_profileImg rounded-5' />
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item className='rounded-2' href='/profile'>Profile</Dropdown.Item>
        <Dropdown.Item className='rounded-2' href='/resetPassword'>Reset Password</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
                    </div> */}
                    </Col>
                </Row>
            </div>

            {/* offcanva content */}
            <Offcanvas show={show} onHide={handleClose} backdrop="static" className='header_canvas'>
                <Offcanvas.Body>
                    <div className='header_canva_header pb-2 d-flex justify-content-between align-items-center'>
                        <img src={require('../assets/images/headerlogo.png')} className='img-fluid img_mobile_header' />
                        <button className='cmn_modal_closer rounded-5' onClick={handleClose}><i class="fa-solid fa-xmark" /></button>
                    </div>
                    <ul className="pt-4">
                        {/* <li className="rounded-end-5 mb-3">
                <NavLink to='/' className="sidebar_links d-flex gap-3 justify-content-start align-items-center p-2 ps-4">
                    <img src={require('../assets/images/grid.svg').default} className="img-fluid sidebar_linkImg" />
                    <p className="sidebar_link_hint">Dashboard</p>
                </NavLink>
            </li> */}

                        <li className="rounded-end-5 mb-3">
                            <NavLink to='/launchpadlist' className="sidebar_links d-flex gap-3 justify-content-start align-items-center p-2 ps-4">
                                <img src={require('../assets/images/exchange.svg').default} className="img-fluid sidebar_linkImg" />
                                <p className="sidebar_link_hint">Launchpad List</p>
                            </NavLink>
                        </li>



                        {/* <li className="rounded-end-5 mb-3">
                <NavLink to='/userWallet' className="sidebar_links d-flex gap-3 justify-content-start align-items-center p-2 ps-4">
                    <img src={require('../assets/images/wallet.svg').default} className="img-fluid sidebar_linkImg" />
                    <p className="sidebar_link_hint">Users Wallet</p>
                </NavLink>
            </li> */}

                        {/* <li className="rounded-end-5 mb-3">
                <NavLink to='/supportTicket' className="sidebar_links d-flex gap-3 justify-content-start align-items-center p-2 ps-4">
                    <img src={require('../assets/images/ticket.svg').default} className="img-fluid sidebar_linkImg" />
                    <p className="sidebar_link_hint">Support Ticket</p>
                </NavLink>
            </li> */}

                        <li className="rounded-end-5 mb-3">
                            <NavLink to='/settings' className="sidebar_links d-flex gap-3 justify-content-start align-items-center p-2 ps-4">
                                <img src={require('../assets/images/setting.svg').default} className="img-fluid sidebar_linkImg" />
                                <p className="sidebar_link_hint">Settings</p>
                            </NavLink>
                        </li>

                        <li className="rounded-end-5 mb-3">
                            <NavLink to='/launchpadSettings' className="sidebar_links d-flex gap-3 justify-content-start align-items-center p-2 ps-4">
                                <img src={require('../assets/images/network.svg').default} className="img-fluid sidebar_linkImg" />
                                <p className="sidebar_link_hint">Launchpad Settings</p>
                            </NavLink>
                        </li>




                        <li className="rounded-end-5 mb-3">
                            <NavLink to='/resetPassword' className="sidebar_links d-flex gap-3 justify-content-start align-items-center p-2 ps-4">
                                <img src={require('../assets/images/editer.svg').default} className="img-fluid sidebar_linkImg" />
                                <p className="sidebar_link_hint">User Settings</p>
                            </NavLink>
                        </li>
                        {/* <li className="rounded-end-5 mb-3">
                <NavLink to='/subscription' className="sidebar_links d-flex gap-3 justify-content-start align-items-center p-2 ps-4">
                    <img src={require('../assets/images/prime.svg').default} className="img-fluid sidebar_linkImg" />
                    <p className="sidebar_link_hint">Subscription</p>
                </NavLink>
            </li> */}
                        <li className="rounded-end-5" onClick={handleShowLogout}>
                            <div className="sidebar_links d-flex gap-3 justify-content-start align-items-center p-2 ps-4">
                                <img src={require('../assets/images/logout.svg').default} className="img-fluid sidebar_linkImg" />
                                <p className="sidebar_link_hint">Logout</p>
                            </div>
                        </li>

                    </ul>




                </Offcanvas.Body>
            </Offcanvas>
            {/* end of offcanva content */}

            {/* modals */}
            <LogoutModal show={showLogout} handleClose={handleCloseLogout} />
            <WalletconnectModal show={showWallet} handleClose={handleCloseWallet} />
            <NetworkModal show={showNetwork} handleClose={handleCloseNetwork} />



            {/* end of modals */}
        </>
    )
}

export default Header