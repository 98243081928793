import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import LogoutModal from "../Modals/LogoutModal";
import { UseProvider } from "../hooks/useWeb3";

function Sidebar() {
    // modal
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


 



    return (
        <>
            <div className="sidebar d-flex flex-column justify-content-between align-items-start">
                <div className="w-100">
                    <div className="sidebar_logo_holder px-4 py-3">
                        <img src={require('../assets/images/headerlogo.png')} className="img-fluid main_logo" />
                    </div>
                    <ul className="pt-4">
                        {/* <li className="mb-3">
                <NavLink to='/' className="sidebar_links rounded-end-5  d-flex gap-3 justify-content-start align-items-center p-2 ps-4">
                    <img src={require('../assets/images/grid.svg').default} className="img-fluid sidebar_linkImg" />
                    <p className="sidebar_link_hint">Dashboard</p>
                </NavLink>
            </li> */}

                        <li className="mb-3">
                            <NavLink to='/launchpadlist' className="sidebar_links rounded-end-5  d-flex gap-3 justify-content-start align-items-center p-2 ps-4">
                                <img src={require('../assets/images/exchange.svg').default} className="img-fluid sidebar_linkImg" />
                                <p className="sidebar_link_hint">Launchpad List</p>
                            </NavLink>
                        </li>



            <li className="mb-3">
                <NavLink to='/createLaunchpad' className="sidebar_links rounded-end-5 d-flex gap-3 justify-content-start align-items-center p-2 ps-4">
                    <img src={require('../assets/images/create.svg').default} className="img-fluid sidebar_linkImg" />
                    <p className="sidebar_link_hint">Create Launchpad</p>
                </NavLink>
            </li>

                   

                        <li className="mb-3">
                            <NavLink to='/settings' className="sidebar_links rounded-end-5  d-flex gap-3 justify-content-start align-items-center p-2 ps-4">
                                <img src={require('../assets/images/setting.svg').default} className="img-fluid sidebar_linkImg" />
                                <p className="sidebar_link_hint">Settings</p>
                            </NavLink>
                        </li>

                        {/* <li className="mb-3">
                            <NavLink to='/launchpadSettings' className="sidebar_links rounded-end-5  d-flex gap-3 justify-content-start align-items-center p-2 ps-4">
                                <img src={require('../assets/images/network.svg').default} className="img-fluid sidebar_linkImg" />
                                <p className="sidebar_link_hint">Launchpad Settings</p>
                            </NavLink>
                        </li> */}

                        <li className="mb-3">
                <NavLink to='/feeSettings' className="sidebar_links rounded-end-5  d-flex gap-3 justify-content-start align-items-center p-2 ps-4">
                    <img src={require('../assets/images/ticket.svg').default} className="img-fluid sidebar_linkImg" />
                    <p className="sidebar_link_hint">Fee Settings</p>
                </NavLink>
            </li> 
                        <li className="mb-3">
                            <NavLink to='/changePassword' className="sidebar_links rounded-end-5  d-flex gap-3 justify-content-start align-items-center p-2 ps-4">
                                <img src={require('../assets/images/editer.svg').default} className="img-fluid sidebar_linkImg" />
                                <p className="sidebar_link_hint">Profile Settings</p>
                            </NavLink>
                        </li>
                        {/* <li className="mb-3">
                <NavLink to='/subscription' className="sidebar_links rounded-end-5  d-flex gap-3 justify-content-start align-items-center p-2 ps-4">
                    <img src={require('../assets/images/prime.svg').default} className="img-fluid sidebar_linkImg" />
                    <p className="sidebar_link_hint">Subscription</p>
                </NavLink>
            </li> */}


                    </ul>
                </div>

                <div className="w-100">
                    <li className="mb-3" onClick={handleShow}>
                        <div className="sidebar_links rounded-end-5  d-flex gap-3 justify-content-start align-items-center p-2 ps-4">
                            <img src={require('../assets/images/logout.svg').default} className="img-fluid sidebar_linkImg" />
                            <p className="sidebar_link_hint">Logout</p>
                        </div>
                    </li>
                </div>

            </div>

            {/* modals */}
            <LogoutModal show={show} handleClose={handleClose} />
            {/* end of modals */}
        </>
    );
}

export default Sidebar;
