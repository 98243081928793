import logo from '../src/assets/images/headerlogo.png';
import './App.css';
import { BrowserRouter, Routes, Route,Switch } from "react-router-dom";
import toast, { Toaster } from 'react-hot-toast';
import Dashboard from './Screens/Dashboard';
import HelperRoute from './helperRoutes';
import '../src/assets/css/Style.css'
import UserWallet from './Screens/UserWallet';
import ResetPassword from './Screens/ResetPassword';
import Launchpadlist from './Screens/Launchpadlist';
import SupportTicket from './Screens/SupportTicket';
import Profile from './Screens/Profile';
import Settings from './Screens/Settings';
import AddSettings from './Screens/AddSettings';
import LaunchpadSettings from "./Screens/LaunchpadSettings";
// import AddNetwork from './Screens/';
import EmailVerification from './Screens/EmailVerification';
import CreateLaunchpad from "./Screens/CreateLaunchpad";
import LaunchpadDetail from "./Screens/LaunchpadDetail";


import FeeSettings from "./Screens/FeeSettings";
import AddFee from "./Screens/AddFee";




import Login from "./Screens/Login";
import Forgotpassword from "./Screens/Forgotpassword";
import Resetnewpassword from "./Screens/Resetnewpassword";
import store from "./store";
import { Provider } from "react-redux";
import ConditionRoute from "./ConditionalRoute";


function App() {
  return (
    <>
      <Provider store={store}>
        <BrowserRouter >
          <div><Toaster /></div>

          <HelperRoute />
          <Switch>

          {/* <Routes> */}


            <Route
              exact
              path="/resetpassword/:authToken"
              component={Resetnewpassword}
            />

            {/* <Route path='/resetpassword/:authToken' element={<Resetnewpassword />}/>   */}

            <Route
              exact
              path="'/verify-old-email/:authToken"
              component={EmailVerification}
            />

            {/* <Route path ='/verify-old-email/:authToken' element={<EmailVerification />}/> */}

            <Route
              exact
              path="/verify-new-email/:authToken"
              component={EmailVerification}
            />

            <ConditionRoute
              exact
              path="/"
              component={Login}
              type={"auth"}
            />

            {/* <Route path='/' element={<Login />} /> */}


            <ConditionRoute
              exact
              path="/login"
              component={Login}
              type={"auth"}
            />

            {/* <Route path='/login' element={<Login />} /> */}

            <ConditionRoute
              exact
              path="/forgotpassword"
              component={Forgotpassword}
              type={"public"}
            />

            {/* <Route path='/forgotpassword' element={<Forgotpassword />} /> */}

            <ConditionRoute
              exact
              path="/resetpassword"
              component={Resetnewpassword}
              type={"public"}
            />
            {/* <Route path='/resetpassword' element={<Resetnewpassword />} /> */}



            {/* <Route path ='/verify-new-email/:authToken' element={<EmailVerification />} /> */}

            <ConditionRoute
              exact
              path="/dashboard"
              component={Dashboard}
              type={"private"}
            />

            {/* <Route path='/dashboard' element={<Dashboard />} /> */}

            <ConditionRoute
              exact
              path="/userWallet"
              component={UserWallet}
              type={"private"}
            />
            {/* <Route path='/userWallet' element={<UserWallet />} /> */}

            <ConditionRoute
              exact
              path="/supportTicket"
              component={SupportTicket}
              type={"private"}
            />

            {/* <Route path='/supportTicket' element={<SupportTicket />} /> */}

            <ConditionRoute
              exact
              path="/launchpadlist"
              component={Launchpadlist}
              type={"private"}
            />

            {/* <Route path='/launchpadlist' element={<Launchpadlist />} /> */}

            <ConditionRoute
              exact
              path="/profile"
              component={Profile}
              type={"private"}
            />

            {/* <Route path='/profile' element={<Profile />} /> */}
            <ConditionRoute
              exact
              path="/settings"
              component={Settings}
              type={"private"}
            />

            {/* <Route path='/settings' element={<Settings />} /> */}

            <ConditionRoute
              exact
              path="/changePassword"
              component={ResetPassword}
              type={"private"}
            />

            {/* <Route path='/changePassword' element={<ResetPassword />} /> */}
            <ConditionRoute
              exact
              path="/addSettings"
              component={AddSettings}
              type={"private"}
            />

            {/* <Route path='/addSettings' element={<AddSettings />} /> */}

            <ConditionRoute
              exact
              path="/launchpadSettings"
              component={LaunchpadSettings}
              type={"private"}
            />

            {/* <Route path='/feeSettings' element={<FeeSettings />} /> */}

            <ConditionRoute
              exact
              path="/feeSettings"
              component={FeeSettings}
              // type={"private"}
            />

<ConditionRoute
              exact
              path="/addFee"
              component={AddFee}
              // type={"private"}
            />


            {/* <ConditionRoute
              exact
              path="/addNetwork"
              component={AddNetwork}
              type={"private"}
            />

            <Route path='/addNetwork' element={<AddNetwork />} /> */}

            <ConditionRoute
              exact
              path="/createLaunchpad"
              component={CreateLaunchpad}
              type={"private"}
            />
            {/* <Route path='/createLaunchpad' element={<CreateLaunchpad/>} /> */}

            <ConditionRoute
              exact
              path="/launchpadDetail/:saleAddress"
              component={LaunchpadDetail}
              type={"private"}
            />

    {/* <Route path='/launchpadDetail' element={<LaunchpadDetail/>} /> */}


          {/* </Routes> */}
          </Switch>

        </BrowserRouter>
      </Provider>
    </>
  );
}

export default App;
