import Web3 from "web3"
import { CHAINS, PROJECT_ID } from "../Config/env";
import toast, { Toaster } from 'react-hot-toast';
// import {sleep} from './UseContract'
import { EthereumProvider } from '@walletconnect/ethereum-provider'
import { walletControl, addLiquiditylocal, addLiquidityETHlocal, removeLiquiditylocal, removeLiquidityETHlocal, swaplocal, approvelocal } from '../Lib/localStorage';

var checkToast = true


export const useWeb3 = async () => {

  const RPC_URL = CURRENT_RPC_URL();
  const CHAIN_ID = CURRENT_CHAIN_ID();
  const httpProvider = new Web3.providers.HttpProvider(RPC_URL)

  let web3;


  if (localStorage.getItem("walletConnect") != null) {
    console.log(localStorage.getItem("walletConnect"), "sessionStorage.getItem")
    // alert("wallet connect call")
    const provider = await EthereumProvider.init({
      projectId: PROJECT_ID, // required
      chains: [CHAIN_ID], // required
      showQrModal: true // requires @walletconnect/modal
    })
    await provider.enable()
    web3 = new Web3(provider);

  }
  else if (typeof window.BinanceChain !== 'undefined') {
    console.log(typeof window.BinanceChain, "sessionStorage.getItem1")
    web3 = new Web3(window.BinanceChain);
    // return web3;
  }

  else if (sessionStorage.getItem("accountInfo") != null) {
    console.log(sessionStorage.getItem("accountInfo"), "sessionStorage.getItem2")
    web3 = new Web3(window.ethereum);
    //   return web3;
  } else {
    console.log(sessionStorage.getItem("accountInfo"), "sessionStorage.getItem3")
    web3 = new Web3(httpProvider);
    //   return web3;
  }
  const chainIds = await web3.eth.getChainId();
  console.log('SwitchChain___web3',parseInt(chainIds) != parseInt(CHAIN_ID),chainIds,CHAIN_ID)
  if (parseInt(chainIds) != parseInt(CHAIN_ID)) {
    console.log('SwitchChain___web3')
    if (checkToast) {
      checkToast = false
      toast.error(`Connected to Wrong Network !`,
        {
          // position: position.position,
          // style: {
          //   minWidth: '300px',
          //   minHeight: '55px'
          // }
          // iconTheme: iconTheme
        })

      const data = await SwitchChain();
      if (data) {
        checkToast = true
        // window.location.reload();
      }else if(!data){
        checkToast = true
      }
    }

    // const data = await SwitchChain();
    // if (data) {
    //   window.location.reload();
    // }
  }

  return web3;

}



// export const useWeb3 = async () => {

//   const RPC_URL = CURRENT_RPC_URL();
//   const CHAIN_ID = CURRENT_CHAIN_ID();
//   const httpProvider = new Web3.providers.HttpProvider(RPC_URL)

//   let web3;


//   if (sessionStorage.getItem("accountInfo") != null && sessionStorage.getItem('wallet') == 'walletconnect') {
//     console.log(sessionStorage.getItem("accountInfo"), "sessionStorage.getItem")
//     // alert("wallet connect call")
//     const provider = await EthereumProvider.init({
//       projectId: PROJECT_ID, // required
//       chains: [CHAIN_ID], // required
//       showQrModal: true // requires @walletconnect/modal
//     })
//     await provider.enable()
//     web3 = new Web3(provider);

//   }
//   else if (typeof window.BinanceChain !== 'undefined') {
//     console.log(typeof window.BinanceChain, "sessionStorage.getItem1")
//     web3 = new Web3(window.BinanceChain);
//     // return web3;
//   }

//   else if (sessionStorage.getItem("accountInfo") != null) {
//     console.log(sessionStorage.getItem("accountInfo"), "sessionStorage.getItem2")
//     web3 = new Web3(window.ethereum);
//     //   return web3;
//   } else {
//     console.log(sessionStorage.getItem("accountInfo"), "sessionStorage.getItem3")
//     web3 = new Web3(httpProvider);
//     //   return web3;
//   }
//   const chainIds = await web3.eth.getChainId();
//   console.log('SwitchChain___web3', parseFloat(chainIds) != parseFloat(CHAIN_ID), parseFloat(chainIds), CHAIN_ID)
//   if (parseFloat(chainIds) != parseInt(CHAIN_ID)) {
//     console.log('SwitchChain___web3')
//     if (checkToast) {
//       checkToast = false
//       toast.error(`Connected to Wrong Network !`,
//         {
//           // position: position.position,
//           // style: {
//           //   minWidth: '300px',
//           //   minHeight: '55px'
//           // }
//           // iconTheme: iconTheme
//         })

//       const data = await SwitchChain();
//       if (data) {
//         checkToast = true
//         // window.location.reload();
//       } else if (!data) {
//         checkToast = true
//       }
//     }

//     // const data = await SwitchChain();
//     // if (data) {
//     //   window.location.reload();
//     // }
//   }

//   return web3;

// }


// var CheckToast = true
// export const checkChainId = (chainId) => {
//     try {
//         const CHAIN_ID = CURRENT_CHAIN_ID();
//         // let CheckToast = localStorage.getItem("NetworkToast")
//         console.log(CheckToast, typeof CheckToast, "CheckToast")
//         if (CheckToast) {
//             // let Toast = localStorage.setItem("NetworkToast",true)
//             // console.log("Toast",Toast,parseInt(chainId) != parseInt(CHAIN_ID),chainId,CHAIN_ID)
//             if (parseInt(chainId) != parseInt(CHAIN_ID)) {
//                 // console.log("Toast",Toast)
//                 toast.error(`Connected to Wrong Network !`,
//                     {
//                         position: position.position,
//                         style: style,
//                         iconTheme: iconTheme
//                     })

//             }
//         }
//         CheckToast = false
//         return true;
//     }
//     catch (err) {
//         console.log("checkChainId", err)
//     }
// }

export const usehttpProvider = async () => {

  const RPC_URL = CURRENT_RPC_URL();
  const CHAIN_ID = CURRENT_CHAIN_ID();
  console.log("RPC_URLRPC_URL", RPC_URL);
  const httpProvider = new Web3.providers.HttpProvider(RPC_URL)

  let web3;


  web3 = new Web3(httpProvider);

  const chainIds = await web3.eth.getChainId();
  console.log("chainIds_chainIds_V", parseFloat(chainIds));
  if (parseFloat(chainIds) != parseInt(CHAIN_ID)) {
    const data = await SwitchChain();
    if (data) {
      window.location.reload();
    }
  }

  return web3;

}



export const UseProvider = async () => {
  const RPC_URL = CURRENT_RPC_URL();
  const CHAIN_ID = CURRENT_CHAIN_ID();
  var provider = window.BinanceChain;

  if (localStorage.getItem("walletConnect") != null) {
    const provider = await EthereumProvider.init({
      projectId: PROJECT_ID, // required
      chains: [CHAIN_ID], // required
      showQrModal: true // requires @walletconnect/modal
    })
    await provider.enable()


    return provider;
  }
  else if (typeof window.BinanceChain !== 'undefined') {

    return window.BinanceChain;
  }
  else if (sessionStorage.getItem("accountInfo") != null) {
    return window.ethereum;
  }
}



export const CURRENT_RPC_URL = () => {
  const index = GetChainIndex()
  console.log('====================================');
  console.log('CURRENT_RPC_URL', index);
  console.log('====================================');
  return CHAINS[index].RPC_URL;
}

export const GetChainIndex = () => {

  return localStorage.getItem("CHAIN") ? localStorage.getItem("CHAIN") : "0";
}

export const CURRENT_CHAIN_ID = () => {
  const index = GetChainIndex()
  console.log("index>>>>", index);
  return CHAINS[index].CHAIN_ID;
}

export const SwitchChain = async () => {
  console.log('SwitchChain')
  const RPC_URL = CURRENT_RPC_URL();
  const CHAIN_ID = CURRENT_CHAIN_ID();
  // Check if MetaMask is installed
  // MetaMask injects the global API into window.ethereum
  const hexString = CHAIN_ID.toString(16);
  if (window.ethereum) {
    try {
      // check if the chain to connect to is installed

      const data = window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: "0x" + hexString }], // chainId must be in hexadecimal numbers
      });
      console.log('Switching_Network1')
      await toast.promise(data, {
        loading: 'Switching Network ...',
        success: 'Network Switched Successfully',
        error: 'Error ! When Switching Network',
      }, {
        style: {
          minWidth: '300px',
          minHeight: '55px'
        }
      });
      return true;
    } catch (error) {
      // This error code indicates that the chain has not been added to MetaMask
      // if it is not, then install it into the user MetaMask
      if (error.code === 4902) {
        try {
          const data = window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId: "0x" + hexString,
                rpcUrl: RPC_URL,
              },
            ],
          });
          console.log('Switching_Network2')
          await toast.promise(data, {
            loading: 'Switching Network ...',
            success: 'Network Switched Successfully',
            error: 'Error ! When Switching Network',
          }, {
            style: {
              minWidth: '300px',
              minHeight: '55px'
            }
          });
          return true;
        } catch (addError) {
          console.error(addError);
          toast.error(`Error : ${addError}`,
            {
              style: {
                minWidth: '300px',
                minHeight: '55px'
              }
            });
        }
      }
      console.error(error);
      return false
    }
  } else {
    // if no window.ethereum then MetaMask is not installed

    return false;

  }
}

export const SwitchChainS = async (i) => {
  const RPC_URL = CHAINS[i].RPC_URL;
  const CHAIN_ID = CHAINS[i].CHAIN_ID;
  // Check if MetaMask is installed
  // MetaMask injects the global API into window.ethereum
  const hexString = CHAIN_ID.toString(16);
  if (window.ethereum) {
    try {
      // check if the chain to connect to is installed

      const data = window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: "0x" + hexString }], // chainId must be in hexadecimal numbers
      });
      console.log('Switching_Network3')
      await toast.promise(data, {
        loading: 'Switching Network ...',
        success: 'Network Switched Successfully',
        error: 'Error ! When Switching Network',
      }, {
        style: {
          minWidth: '300px',
          minHeight: '55px'
        }

      },
        // await sleep(1000)
      );
      return true;
    } catch (error) {
      // This error code indicates that the chain has not been added to MetaMask
      // if it is not, then install it into the user MetaMask
      if (error.code === 4902) {
        try {
          const data = window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId: "0x" + hexString,
                rpcUrl: RPC_URL,
              },
            ],
          });
          console.log('Switching_Network4')
          await toast.promise(data, {
            loading: 'Switching Network ...',
            success: 'Network Switched Successfully',
            error: 'Error ! When Switching Network',
          }, {
            style: {
              minWidth: '300px',
              minHeight: '55px'
            }
          });
          return true;
        } catch (addError) {
          console.error(addError);
          toast.error(`Error : ${addError}`,
            {
              style: {
                minWidth: '300px',
                minHeight: '55px'
              }
            });
        }
      }
      console.error(error);
    }
  } else {
    // if no window.ethereum then MetaMask is not installed

    return false;

  }
}

export const GetTransaction = async (hash, ContractABI, type) => {
  try {
    const web3 = await useWeb3();
    const result = await web3.eth.getTransaction(hash)
    //  async (error, transaction) => {
    console.log("getTransaction", result)
    const { input, from } = result;
    let Abi = await ContractABI.find((val) => (val.name == type))
    console.log(Abi.inputs, "Abi.inputs")
    let decodeData = web3.eth.abi.decodeParameters(Abi.inputs, input.slice(10))
    console.log("decodeData", decodeData)
    // })
    return decodeData
  }
  catch (err) {
    console.log("err", err)
  }
}



export const setChainId = (i) => {
  try {
    localStorage.setItem("CHAIN", i);
  }
  catch (err) {
    console.log("setChainId", err)
  }
}

export const getChainId = () => {
  try {
    const index = localStorage.getItem("CHAIN");
    return index != null ? index : "0";
  }
  catch (err) {
    console.log("getChainId", err)
  }
}
