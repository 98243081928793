
export const PROXY = {
	56: "", //bsc mainnet

	97: '0xf55e1B3095573AAb3Cd19e145B8535f63729E882',// new bsc testnet

	137: "", //polygon mainnet

	1: "",

	3: "",

	43113: "", // avalanche testnet 

	42161: ""  //arbitrum
};

export const XLAUNCH = {
	56: "", //bsc mainnet

	97: '0xFBcB54f89a0707C77b15e69b9dede74F636080B4',

	137: "", //polygon mainnet

	1: "",

	3: "",

	43113: "", // avalanche testnet 

	42161: ""  //arbitrum
};


export default {
	xtoken: {

		//bsc
		56: '', //M
		97: '0x846FE8E884B862F720Cc7728eE42f1a29300AB17',//T

		//Eth
		1: '', //M
		11155111: '', //T

		//Avax
		43114: '0x296EFfbF5Cc1Dad8035302E54A6360C09896dc45', //M
		43113: '', //T

		//Arbitrum
		42161: '', //M
		421614: '', //T

		//zkSync
		324: '', //M
		280: '', //T

	}
}